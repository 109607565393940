import * as React from "react"
import { Link } from "gatsby"
import * as contactLinkStyles from "./contactlink.module.css"

const ContactLink = () => {
    return (
        <h1 className={contactLinkStyles.heading}>
            <Link to="/contact/">Entre em contato&#xFEFF; →</Link>
        </h1>
    )
}

export default ContactLink