import React, { Component } from 'react';
import Container from "../components/container"
import ContactLink from "../components/contactlink"
import Copyright from "../components/copyright"
import Footer from "../components/footer"
import Header from "../components/header"
import Navbar from "../components/navbar"

import img_1 from "../images/amare_img_1_h.jpg"
import img_2 from "../images/pemalex_img_malharia_2_v.jpg"
import img_3 from "../images/pemalex_img_estamparia_2_h.jpg"
import img_4 from "../images/pemalex_img_fabrica_h.jpg"

import * as productStyles from "./product.module.css"

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFirstSectionOpen: false,
      isSecondSectionOpen: false,
      isThirdSectionOpen: false,
    };
  }

  componentDidMount() {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    if (url.includes('#first-section')) {
      this.setState(state => ({ isFirstSectionOpen: true }))
    } else if (url.includes('#second-section')) {
      this.setState(state => ({ isSecondSectionOpen: true }))
    } else if (url.includes('#third-section')) {
      this.setState(state => ({ isThirdSectionOpen: true }))
    }
  }

  handleFirstSectionClick = () => {
    this.setState(state => ({ isFirstSectionOpen: !this.state.isFirstSectionOpen }))
  }

  handleSecondSectionClick = () => {
    this.setState(state => ({ isSecondSectionOpen: !this.state.isSecondSectionOpen }))
  }

  handleThirdSectionClick = () => {
    this.setState(state => ({ isThirdSectionOpen: !this.state.isThirdSectionOpen }))
  }

  render() {
    return (
    <React.Fragment>
      <Navbar></Navbar>
      <Container>
        <Header headerText="Nossos Produtos" />
        <h3 className="pt-4">
          A Pemalex se destaca por sua ampla gama de produtos de alta qualidade, que abrange <a className={productStyles.link} href="#first-section">malhas</a>, <a className={productStyles.link} href="#second-section">tecidos estampados</a>, e <a className={productStyles.link} href="#third-section">entretelas</a>.
        </h3>
        <div className={productStyles.wrapper}>
          <p>
            As nossas malhas produzidas em poliamida e poliéster oferecem durabilidade, elasticidade e conforto, tornando-as ideais para uma variedade de aplicações, desde roupas esportivas até moda íntima. Além disso, as entretelas são essenciais para dar estrutura e suporte à peças de vestuário, garantindo um acabamento impecável.
          </p>
          <p>
            Os nossos tecidos estampados, criados pelo nosso estúdio de design interno, <em>Amaré Design</em>, são feitas à mão e digitalizadas, incorporando padrões exclusivos que atendem às tendências da moda e aos gostos mais exigentes dos nossos clientes.
          </p>
        </div>
        <img data-sal="slide-left" data-sal-delay="100" data-sal-easing="ease-out-circ" data-sal-duration="1900" src={img_1} alt="Manequim" />
        <h3 className={productStyles.paragraph}>Juntos, esses produtos representam a excelência e a versatilidade da Pemalex, que se empenha em oferecer soluções de alta qualidade para a indústria da moda, trazendo tecnologias de última gerações, como a máquina digital <em>Reggiani</em>, tear circulares <em>Pilotelli</em>, HT <em>Fong's</em>, e ramas <em>Ewhwa</em>.</h3>
        <ContactLink />
      </Container>
      <div aria-hidden id='first-section' className={productStyles.malharia} onClick={this.handleFirstSectionClick} onKeyDown={this.handleFirstSectionClick}>
        <div className={productStyles.titleWrapper} data-sal="slide-left" data-sal-delay="500" data-sal-easing="ease-in-out-sine" data-sal-duration="1200">
          <h1>Malharia</h1>
          {!this.state.isFirstSectionOpen && (<h1>+</h1>)}
          {this.state.isFirstSectionOpen && (<h1>x</h1>)}
        </div>
      </div>
      {this.state.isFirstSectionOpen && (
        <div className={productStyles.firstSection}>
          <div className={productStyles.sectionWrapper}>
            <img src={img_2} alt="Malharia" />
            <div className={productStyles.wrapper}>
              <div className={productStyles.left}>
                <h3>Malhas de Poliamida</h3>
              </div>
              <div className={productStyles.right}>
                <p>Composta de poliamida e elastano, usados principalmente no ramo de confecção de moda íntima, praia e fitness.</p>
              </div>
              <div className={productStyles.left}>
                <h3>Malhas de Poliéster</h3>
              </div>
              <div className={productStyles.right}>
                <p>Composta de poliéster e elastano, usado na fabricação de bojos para moda íntima e moda praia.</p>
              </div>
              <div className={productStyles.left}>
                <h3>Malhas de Viscose</h3>
              </div>
              <div className={productStyles.right}>
                <p>Composta de viscose e elastano, usado na confecção de moda feminina.</p>
              </div>
              <div className={productStyles.left}>
                <h3>Malhas de Algodão</h3>
              </div>
              <div className={productStyles.right}>
                <p>Composta de algodão e elastano, usado na confecção de moda feminina.</p>
              </div>
            </div>
          </div>
        </div>)
      }

      <div aria-hidden id='second-section' className={productStyles.estamparia} onClick={this.handleSecondSectionClick} onKeyDown={this.handleFirstSectionClick}>
        <div className={productStyles.titleWrapper} data-sal="slide-left" data-sal-delay="500" data-sal-easing="ease-in-out-sine" data-sal-duration="1200">
          <h1>Estamparia</h1>
          {!this.state.isSecondSectionOpen && (<h1>+</h1>)}
          {this.state.isSecondSectionOpen && (<h1>x</h1>)}
        </div>
      </div>
      {this.state.isSecondSectionOpen && (
        <div className={productStyles.secondSection}>
          <div className={productStyles.sectionWrapper}>
          <img src={img_3} alt="Estamparia" />
            <div className={productStyles.wrapper}>
              <div className={productStyles.left}>
                <h3>Impressão digital</h3>
              </div>
              <div className={productStyles.right}>
                <p>Desenvolvimento e criação de estampas com impressão digital em tecido próprio em viscose, linho, e algodão.</p>
              </div>
              <div className={productStyles.left}>
                <h3>Sublimação</h3>
              </div>
              <div className={productStyles.right}>
                <p>Desenvolvimento e criação de estampas, com sublimação em tecido próprio em poliéster.</p>
              </div>
            </div>
          </div>
        </div>)
      }

      <div aria-hidden id='third-section' className={productStyles.entretelas} onClick={this.handleThirdSectionClick} onKeyDown={this.handleFirstSectionClick}>
        <div className={productStyles.titleWrapper} data-sal="slide-left" data-sal-delay="500" data-sal-easing="ease-in-out-sine" data-sal-duration="1200">
          <h1>Entretelas</h1>
          {!this.state.isThirdSectionOpen && (<h1>+</h1>)}
          {this.state.isThirdSectionOpen && (<h1>x</h1>)}
        </div>
      </div>
      {this.state.isThirdSectionOpen && (
        <div className={productStyles.thirdSection}>
          <div className={productStyles.sectionWrapper}>
          <img src={img_4} alt="Fabrica" />
            <div className={productStyles.wrapper}>
              <div className={productStyles.left}>
                <h3>Entretelas de Malha</h3>
              </div>
              <div className={productStyles.right}>
                <p>Usadas principalmente na confecção feminina, dando um caimento perfeito e melhor toque e maciez na roupa.</p>
              </div>
              <div className={productStyles.left}>
                <h3>Entretelas Não-Tecidas</h3>
              </div>
              <div className={productStyles.right}>
                <p>Variedade de entretelas não-tecidas que dão forma, maciez e conforto à roupa confeccionada.</p>
              </div>
            </div>
          </div>
        </div>)
      }

      <div className={productStyles.parallax}></div>
      <Footer />
      <Copyright />
    </React.Fragment>
    )
  }
}

export default Product;